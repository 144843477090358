<template>
  <div>
    <div class="ta-left px-0 mt-2 col-12 mgm-filter-header vertical-align">
      <span class="fz-16 openSans-300">{{ $t('mgm.buttons.filters') }}</span>
      <span class="fz-16 ml-auto openSans-400 cursor-pointer align-items-baseline txt-viridian" @click="cancelFilter">{{ $t('mgm.buttons.clearAll') }}</span>
      <span class="fz-16 ml-2 openSans-400 cursor-pointer align-items-baseline txt-viridian" @click="applyFilter">{{ $t('mgm.buttons.applyFilter') }}</span>
    </div>

    <div class="scrollable-sidebar">
      <div class="ta-left px-2 mt-2 pb-3 col-12 mgm-filter-section">
        <date-range-picker v-model="criteria.dateRange" class="w-100" ref="picker" :locale-data="{ firstDay: 0, format: 'yyyy-mm-dd' }"
                           opens="right" :show-dropdowns="true" :auto-apply="true">
          <template v-slot:input="picker" style="min-width: 350px;">
            {{ picker.startDate | date }} - {{ picker.endDate  | date }}
          </template>
        </date-range-picker>
      </div>

      <div class="ta-left px-2 mt-2 pb-3 col-12 mgm-filter-section">
        <b-form-checkbox class="vertical-align favorites fz-16 openSans-300" v-model="criteria.favorite" size="lg">
          <img class="margin-action-icons mx-0" src="/assets/images/icon_star_enabled.svg" /> {{ $t('mgm.favoriteFilter.title') }}
        </b-form-checkbox>
        <span class="fz-14 d-block openSans-400 starred-disclaimer">{{ $t('mgm.favoriteFilter.description') }}</span>
      </div>

      <!-- ======================================================= -->
        <div :class="currentCategory ? '' : 'mgm-filter-section'" class="ta-left px-2 pb-3 mt-3 col-12">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Category</span>
            <div>
            <multiselect v-model="currentCategory" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
            </multiselect>
            </div>
        </div>
        <div :class="currentCategory ? 'mgm-filter-section' : '' " class="ta-left col-12 px-2 pb-3">
            <div v-if="currentCategory">
                <div class="col-12 px-0">
                    <div class="d-block mb-3" v-for="categorySelected in currentCategory" :key="categorySelected.language">
                    <span class="d-block cursor-pointer mb-3" @click="categorySelected.displayRange = !categorySelected.displayRange"> 
                        <span class="fz-14 openSans-300 category-chip">{{categorySelected.name}}</span>
                        <span class="fz-14 openSans-600">
                            {{categorySelected.range[0] + ' - ' + categorySelected.range[1]}}
                        </span>
                    </span>
                    <vue-slider v-show="categorySelected.displayRange" 
                    tooltip="always" 
                    class="ce-slider px-4" 
                    v-model="categorySelected.range"></vue-slider>
                    </div>

                </div>
            </div>
        </div>
        <div class="ta-left mt-2 px-2 pb-3 col-12 mgm-filter-section">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Group Tags</span>
            <b-form-checkbox v-for="metatag in metaTagsSource"
                            v-model="selectedGroupTags"
                            :key="metatag"
                            :class="metatag"
                            :value="metatag" >{{metatag.replace(/_/g," ")}}</b-form-checkbox>
        </div>
        <div class="ta-left mt-2 px-2 mb-3 pb-3 col-12">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Tags</span>
            <vue-tags-input
                class="ask-tags-input autocomplete-container"
                v-model="tag"
                :tags="selectedTags"
                :add-only-from-autocomplete="true"
                :autocomplete-items="filteredItems"
                @tags-changed="newTags => selectedTags = newTags"
                />
        </div>
        <!-- ======================================================= -->
    </div>
  </div>
</template>

<script>
import LanguageIntelligenceService from '@/modules/languageInteligence/services/LanguageIntelligenceService.js'

export default {
  data () {
    return {
      criteria: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        favorite: false
      },
      date: '',
      // ========================================================
      currentCategory: '',
      categoriesRangeSelector: [],
      metaTagsSource: [],
      tagsSource: [],
      currentRangeSelector: [0,100],
      tag: '',
      selectedTags: [],
      selectedGroupTags: [],
      categoriesWithTags: {},
      sortCriteria: {
        sort: false,
        order: 'descending'
      },
      tagMessage: 'Display Tags',
      orders: [
        { value: 'ascending', text: 'Low to high' },
        { value: 'descending', text: 'High to low' }
      ],
      picker: '',
      linkedCalendars: true,
      options: [
        { name: 'Vue.js', language: 'JavaScript', range: [0,100], displayRange: false },
        { name: 'Rails', language: 'Ruby', range: [0,100], displayRange: false },
        { name: 'Sinatra', language: 'Ruby', range: [0,100], displayRange: false },
        { name: 'Laravel', language: 'PHP', range: [0,100], displayRange: false },
        { name: 'Phoenix', language: 'Elixir', range: [0,100], displayRange: false }
    ]
    }
  },
  computed: {
    filteredItems() {
      return this.tagsSource.filter(i => {
        if(i.text) {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
         } else {
           return
         }
      })
    },
  },
  methods: {
    applyFilter() {
      this.$emit('applyFilters', this.criteria)
    },
    cancelFilter() {
      var startDate = new Date()
      startDate.setMonth(startDate.getMonth() - 2)
      this.criteria = {
        dateRange: {
          startDate: startDate,
          endDate: new Date()
        },
        favorite: false
      }
      this.$emit('applyFilters', this.criteria)
    },
    getCategoryTags() {
      LanguageIntelligenceService.getCategoryMetaTags(this.$router.currentRoute.params.bot).then(response => {
        this.categoriesWithTags = response.data
      })
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString().split(' ')[0] : ''
    }
  },
  created() {
    this.getCategoryTags()
    var startDate = new Date()
    this.criteria.dateRange.startDate = startDate.setMonth(startDate.getMonth() - 2)
    this.criteria.dateRange.endDate = new Date()
  }
}
</script>

<style lang="scss" scoped>
  .category-chip {
    background-color: cornflowerblue;
    padding: 4px;
    border-radius: 4px;
    color: white;
  }

  .scrollable-sidebar {
    max-height: 94vh;
    height: auto;
    min-height: 740px;
  }

  .mgm-filter-header-section-color {
    color: var(--black);
  }

  .mgm-filter-section {
    border-bottom: 1px solid var(--gray);
  }

  .starred-disclaimer {
    color: #c4c4c4;
  }

  .mgm-filter-header {
    background-color: #f8f8f8;
    border-bottom: 1px solid #c4c4c4;
    margin-left: 0px;
    margin-top: 0px !important;
    padding: 8px !important;
  }

  .filter-button-adjust {
    min-width: unset !important
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--viridian);
  }

  button.button-primary, button.button-secondary {
    width: 100% !important
  }

  button.button-primary > img.inversed-svg {
    filter: grayscale(100%) brightness(400%);
    margin-right:10px;
  }

  .ask-tags-input {
    margin-bottom: 10px;
    min-height: unset;
  }

  .mgm-filter-title {
    color: var(--ocean-blue)
  }

  .ce-content-select {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::v-deep {
    .ce-slider {
      margin-top: 50px !important;
    }

    div.calendars {
      display: flex !important;
      flex-wrap: nowrap;
    }

    .vue-slider-dot-tooltip-inner {
      background-color: var(--viridian);
      border-color: var(--viridian)
    }

    .vue-slider-process {
      background-color: var(--viridian);
    }

    footer.modal-footer {
      justify-content: space-between;
    }

    div.modal-dialog {
      max-width: 1207px;
      height: auto;
      max-height: 80vh;
    }

    div.modal-backdrop {
      background-color: rgba(255,255,255,0.55);
    }

    header.modal-header {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .custom-checkbox {
      color: var(--dark);
      text-transform: capitalize;
    }

    .custom-control-label span, span.cursor-pointer {
      font-family: OpenSans;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark);
    }

    .custom-control-label::before {
      border: 1px solid var(--viridian);
      border-radius: 6px;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      background-color: var(--viridian);
      border-radius: 6px;
    }

    .custom-control-input:checked + label.custom-control-label span,
    .custom-control.custom-checkbox .custom-control-input:checked + span.cursor-pointer {
      color: black;
    }
  }
</style>
