import moment from 'moment'
import Repository from '@/modules/common/utils/Repository.js'

export default {
  get(projectId, botId, filters) {
    return Repository.dbService.get('/ivdr/project/', {
      params: {
        bot_name: botId,
        project_id: projectId,
        from_date: moment(filters.dateRange.startDate).format('YYYY-MM-DD'),
        to_date: moment(filters.dateRange.endDate).format('YYYY-MM-DD'),
        favorite: filters.favorite
      }
    })
  },
}