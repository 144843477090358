<template>
  <b-card class="mb-1" no-body :ref="`extract-${ document.passage.id }`">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <div class="d-flex">
        <favourite-button class="vertical-align-top" :passage="document.passage"></favourite-button>
        <div class="title-container ta-left">
          <span class="fz-16 openSans-600">{{ document.title }} </span>
          <div class="d-flex my-2">
            <span v-for="category in document.categories" class="mr-2" :key="category.tag">
              <span class="fz-14 ivdr-category-pill">{{ category.tag }}</span>
              <span class="fz-14 ivdr-percentage-pill">{{ category.percentage }}</span>
            </span>
          </div>
        </div>
        <feedback-popover :passage="document.passage"></feedback-popover>
        <div>
          <img class="collapse-arrow" :src="'/assets/images/icon_collapse.svg'" v-b-toggle="`extract-${ document.passage.id }`"/>
        </div>
      </div>
    </b-card-header>
    <b-collapse :id="`extract-${ document.passage.id }`" role="tabpanel">
      <b-card-body>
        <div class="margin-passages" v-html="document.passage.html"></div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>

import FeedbackPopover from '@/modules/medicalGuidelineManagement/components/FeedbackPopover.vue'
import FavouriteButton from '@/modules/ivdr/components/FavouriteButton.vue'

export default {
  name: 'DocumentExtracts',
  components: { FeedbackPopover, FavouriteButton },
  props: {
    document: Object
  }
}
</script>
<style lang="scss" scoped>
  .ivdr-category-pill {
    background-color: cornflowerblue;
    padding: 4px 4px 4px 10px;
    border-radius: 20px 0px 0px 20px;
    border: 1px solid cornflowerblue;
    color: white;
  }

  .ivdr-percentage-pill {
    background-color: white;
    padding: 4px 10px 4px 4px;
    border-radius: 0px 20px 20px 0px;
    color: cornflowerblue;
    border: 1px solid cornflowerblue;
  }

  img[aria-expanded="true"]{
        transform: rotate(-180deg);
  }

  .title-container {
    margin-top: 2px;
    width: 100%;
    max-width: 1100px;
  }

  .feedback-popover-button {
    margin-left: -1px;
    border-radius: 0px 6px 6px 0px !important;
    border: unset !important;
    text-align: right;
    padding-right: 40px;
    padding-top: 0px !important;
    height: 30px !important;
  }

  .passage-serial {
    color: #b4b4b4;
  }

  .mgm-card-header {
    border-bottom: 1px solid #a4a4a4;
  }
  .passage-mgm-card {
    border: unset;
  }

  div.card {
    border-radius: 0.25rem 0px 0.25rem 0.25rem;
  }
  
  .passage-header-right {
    line-height: 30px;
  }
  
  .library-card-tag {
    margin-left: -20px;
    span {
      margin-left: 15px;
    }
  }
  .selected-category-chip {
    background-color: var(--blue) !important;
    float: right;
    height: 24px;
    padding: 5px 8px;
    line-height: 16px;
  }

  .category-chip {
    background-color: var(--completed);
    color: white;
    border-radius: 5px;
    height: 24px;
    padding: 5px 8px;
    line-height: 16px;
    margin: 0px 4px;
    text-transform: capitalize;
  }

</style>
