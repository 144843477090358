<template>
  <div class="container-base section-container-padding bg-white">
    <preloader v-if="!documents" type="modal" class="mt-5"/>
    <div v-if="documents != null" >
      <div class="ta-left my-4">
        <span class="fz-18 openSans-600">Post-Market Surveillance</span>
      </div>
      <b-row class="mgm-toolbar mb-2 mx-0">
        <b-col class="text-left my-2">
          <b-button :title="showTags ? 'Hide Tags' : 'Show Tags'" :class="showTags ? 'button-primary white-icon' : 'button-secondary clean-border'"
                    class="icon-button col-12 ml-1"  @click="displayTags">
            <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_mgm_tags.svg" />
          </b-button>
          <b-button :title="showFilters ? 'Hide Filters' : 'Show Filters'" :class="showFilters ? 'button-primary white-icon' : 'button-secondary clean-border'"
                    class="icon-button col-12 ml-1" @click="displayFilters">
            <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_mgm_filters.svg" />
          </b-button>
        </b-col>
      </b-row>
      <div :class="(showFilters) ? 'offset-3 col-9 pr-0' : 'col-12 px-0'" class="d-flex justify-content-between my-3">
        <span class="fz-16 openSans-600">Abstracts - {{ documents.length }} </span>
        <span v-b-toggle="idRefs" class="fz-14 openSans-400 color-viridian">Toggle Abstracts</span>
      </div>
      <b-row>
        <div class="col-3 mb-10" v-if="showFilters">
          <div class="card sticky-filters">
            <filter-column @applyFilters="filterData"></filter-column>
          </div>
        </div>
        <div :class="showFilters ? 'col-9' : 'col-12'">
          <div class="tags-visible d-flex px-0">
            <div class="accordion w-100" role="tablist">
              <document-extracts v-for="document in documents" v-bind:key="document.id" :document="document"></document-extracts>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import FilterColumn from '@/modules/ivdr/components/FilterColumn'
import DocumentExtracts from '@/modules/ivdr/components/DocumentExtracts'
import IvdrService from '@/modules/ivdr/services/IvdrService'
import Preloader from '@/modules/common/components/Preloader.vue'


const $ = require('jquery')
window.$ = $

export default {
  name: "ExtractExplorer",
  components: { FilterColumn, DocumentExtracts, Preloader },
  data () {
    return {
      showTags: false,
      showFilters: false,
      documents: null,
      unfilteredDocuments: [],
      // ============================================================================
      filtered: false,
      filterFavorite: false,
      rangeSelector: [0,100],
      selectedCategory: null,
      selectedGroupTags: null,
      selectedTags: null,
      metaTags: [],
      tags: [],
      categories: [{
        value: "Analyte Subject",
        text: "Analyte Subject",
      },{
        value: "Intended Use",
        text: "Intended Use",
      },{
        value: "Clinical Study",
        text: "Clinical Study",
      }],
      adocuments: [{
        title: "Relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        },{
          tag: "Analyte Subject",
          percentage: "65%"
        },{
          tag: "Analytical Study",
          percentage: "89%"
        }],
        id: 1,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "This is another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        },{
          tag: "Analyte Subject",
          percentage: "65%"
        },{
          tag: "Analytical Study",
          percentage: "89%"
        },{
          tag: "Analytical Study",
          percentage: "19%"
        }],
        id: 2,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "Yet another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        }],
        id: 3,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "Relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        },{
          tag: "Analyte Subject",
          percentage: "65%"
        }],
        id: 4,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "This is another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Analytical Study",
          percentage: "89%"
        }],
        id: 5,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "Yet another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        },{
          tag: "Analyte Subject",
          percentage: "65%"
        },{
          tag: "Analytical Study",
          percentage: "89%"
        },{
          tag: "Analytical Study",
          percentage: "19%"
        }],
        id: 6,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "Relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        }],
        id: 7,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "This is another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results in the reverse syphilis testing algorithm",
        categories: [{
          tag: "Non-animal study",
          percentage: "34%"
        },{
          tag: "Analyte Subject",
          percentage: "65%"
        },{
          tag: "Analytical Study",
          percentage: "89%"
        },{
          tag: "Analytical Study",
          percentage: "19%"
        }],
        id: 8,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      },{
        title: "Yet another relationship between diasorin liaison treponema pallidum antibody indices and confirmatory assay results",
        categories: [{
          tag: "Analyte Subject",
          percentage: "65%"
        },{
          tag: "Analytical Study",
          percentage: "89%"
        }],
        id: 9,
        content: "The most widely used initial assay for detecting HCV antibodies is the enzyme inmunoassay. A positive enzyme immunoassay should be followed by a confirmatory test. When used in low-risk groups, an enzyme inmmunoassay should be followed by a confirmatory test."
      }],
      idRefs: []
    }
  },
  methods: {
    displayTags() {
      this.showTags = !this.showTags
      if(this.showTags) {
        $(".li-tagged-text.tags-invisible").removeClass("tags-invisible")
      } else {
        $(".li-tagged-text").addClass("tags-invisible")
      }
    },
    displayFilters() {
      this.showFilters = !this.showFilters
    },
    filterData(criteria) {
      this.getIvdrData(criteria)
    },
    getIvdrData(filters) {
      let routeParams = this.$router.currentRoute.params
      IvdrService.get(routeParams.projectId, routeParams.bot, filters).then(response => {
        this.documents = response.data
      })

    }
  },
  created() {
    var startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 2)
    let filters = {
      dateRange: {
        startDate: startDate,
        endDate: new Date()
      },
      favorite: false
    }
    this.getIvdrData(filters)
  }
}
</script>

<style lang="scss" scoped>

    .color-viridian {
      color: var(--viridian)
    }

    .mgm-toolbar {
      border: 1px solid #c4c4c4;
      background-color: #f8f8f8;
      border-radius: 7px;
    }

    .white-icon {
        img {
          filter: brightness(10)
        }
    }

    .clean-border {
      border: unset !important;
    }

    .mgm-toolbar-icon {
      height: auto;
      width: 100%;
    }

    .icon-button {
        min-width: 52px !important;
    }

    .document-select {
        width: 56%;
        color: #28a745;
        border-color: #28a745;
        margin-top: 10px;
    }

    .full-height{
        min-height:90vh;
    }
    .tabs-menu {
        margin-left: -35px;
    }

    .padding-tabs {
        margin-left: 10px;
    }

    ::v-deep {

        div.custom-control.custom-checkbox.favorites label.custom-control-label {
            color: var(--black);
            padding: 2px 6px;
            font-size: 16px;
            line-height: 24px;
        }

        .mgm-document-select  {
            z-index: 800;

            span.vs__selected {
                    color: var(--viridian);
                    width: 1024px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
            }
            ul.vs__dropdown-menu li {
                    width: 700px;
                    display: inline-block;
                    white-space: inherit;
            }
        }

        .li-tag.tags-invisible {
            background-color: transparent !important;
            color: #2c3e50 !important;
            padding: none !important;
            border-radius: 0px !important;
            border: unset !important;
            display: none !important;
        }

        .li-tagged-text.tags-invisible {
            background-color: transparent !important;
            color: #2c3e50 !important;
            padding: none !important;
            border-radius: 0px !important;
            border: unset !important;
        }

        .li-tagged-text, div.custom-control.custom-checkbox label.custom-control-label{
            color: var(--black);
            padding: 2px 6px;
            border-radius: 5px;
        }

        .li-tag{
            padding: 1px 4px;
            border-radius: 5px;
            color: white;
        }

        div.custom-control.custom-checkbox {
          margin: 5px 0px
        }

        div.inside-tabs > div > ul.nav-tabs {
            border-bottom: none;
            border-top: none;
            margin-left: 0px;
            background-color: transparent;

        li.nav-item:first-of-type {
            margin-left: 0px;
        }

        li.nav-item a.nav-link {
            color: var(--dark);
            margin-right: 0px;
            min-width: unset;

            span {
                color: var(--dark);
                padding-left: 0px;
            }
        }

        li.nav-item a.nav-link.active {
            color: var(--viridian);
            border: none;
            border-bottom: 1px solid var(--viridian);
            font-weight: 300;

            span {
                padding-left: 0px;
                color: var(--viridian)
            }
        }
    }
}

</style>
